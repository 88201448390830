import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      HWGraphQL {
        page(id: "cG9zdDo2OQ==") {
          acfAbout {
            slike {
              cetrtaSlika {
                sourceUrl
              }
              drugaSlika {
                sourceUrl
              }
              prvaSlika {
                sourceUrl
              }
              tretjaSlika {
                sourceUrl
              }
            }
          }
        }
        infoPage: page(id: "cG9zdDozMA==") {
          acfMeta {
            informacije {
              delovnicasdnevi
              delovnicasura
              telefonska
              naslov
              email
            }
          }
        }
      }
    }
  `)
  const {
    HWGraphQL: {
      page: { acfAbout },
      infoPage: {
        acfMeta: { informacije },
      },
    },
  } = data

  return (
    <section className="contact pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 mb-60 text-center">
            <h6 className="sub-title">Picerija Jaka</h6>
            <h4 className="title">Kontaktirajte nas</h4>
          </div>
          <div className="col-md-10 offset-md-1">
            <div className="contact-info">
              <div className="row">
                <div className="col-md-4">
                  <div className="item">
                    <div className="cont">
                      <h5>KONTAKT</h5>
                      <p dangerouslySetInnerHTML={{__html: informacije.naslov }}></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="cont">
                      <h5>Telefon in e-pošta</h5>
                      <p dangerouslySetInnerHTML={{__html: informacije.telefonska }}></p>
                      <p>{informacije.email}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="cont">
                      <h5>Odpiralni čas</h5>
                      <p dangerouslySetInnerHTML={{__html: informacije.delovnicasura }}></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
