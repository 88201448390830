import React, { useRef, useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Banner = () => {
  const [settings, setSettings] = React.useState({
    dots: true,
    infinite: true,
    speed: 1000,

    slidesToShow: 1,
    slidesToScroll: 1,
    useCSS: true,
    useTransform: true,
    autoplaySpeed: 5000,
    autoplay: true,
    pauseOnHover: true,
    lazyLoad: false,
    dotsClass: "slider-dots slider-dots--light",
    fade: true
  })
  const data = useStaticQuery(graphql`
    query {
      HWGraphQL {
        page(id: "cG9zdDozMA==") {
          acfMeta {
            slider {
              gumbtekst
              malinaslov
              veliknaslov
              slikaone {
                sourceUrl
              }
            }
            sliderthree {
              malinaslov
              gumbtekst
              veliknaslov
              slikaone {
                sourceUrl
              }
            }
            slidertwo {
              gumbtekst
              malinaslov
              veliknaslov
              slikaone {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  const {
    HWGraphQL: {
      page: {
        acfMeta: { slider, slidertwo, sliderthree },
      },
    },
  } = data
  return (
    <div className="picerija-slider">
      <Slider {...settings}>
        <div className="picerija-slider__item">
          <div className="picerija-slider__item-image" style={{ backgroundImage: `url(${slider.slikaone.sourceUrl})`}}></div>
          <div className="picerija-slider__item-wrap">
            <h3>{slider.malinaslov}</h3>
            <h1>{slider.veliknaslov}</h1>

            <a href="/kontakt" className="butn butn-bg">
              <span>{slider.gumbtekst}</span>
            </a>
          </div>
        </div>
        <div className="picerija-slider__item">
          <div className="picerija-slider__item-image" style={{ backgroundImage: `url(${slidertwo.slikaone.sourceUrl})`}}></div>
          <div className="picerija-slider__item-wrap">
            <h3>{slidertwo.malinaslov}</h3>
            <h1>{slidertwo.veliknaslov}</h1>
            <a href="/jedilnik" className="butn butn-bg">
              <span>{slidertwo.gumbtekst}</span>
            </a>
          </div>
        </div>
        <div className="picerija-slider__item">

          <div className="picerija-slider__item-image" style={{ backgroundImage: `url(${sliderthree.slikaone.sourceUrl})`}}></div>
          <div className="picerija-slider__item-wrap">
            <h3>{sliderthree.malinaslov}</h3>
            <h1>{sliderthree.veliknaslov}</h1>
            <a href="/kontakt" className="butn butn-bg">
              <span>{sliderthree.gumbtekst}</span>
            </a>
          </div>
        </div>
      </Slider>
    </div>
  )
}

export default Banner
