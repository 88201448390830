import React, { useState, useEffect } from "react"
import isEmpty from "lodash"
import { graphql, useStaticQuery } from "gatsby"

import DnevniItem from "./DnevniItem"

const Dnevni = ({ props, day }) => {
  const data = useStaticQuery(graphql`
    {
      allWpJedilnik {
        edges {
          node {
            acfJedilnik {
              jedilnik {
                dan
                imeJedi
                opisJedi
                cenavelikapica
                cenamalapica
                cenaDnevniJedilnik
                cena
                kategorija
              }
            }
          }
        }
      }
    }
  `)

  const {
    allWpJedilnik: { edges },
  } = data
  const [dnevni, setDnevni] = useState([{}])
  const [dnevniDesno, setDesno] = useState([{}])
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    if (data) {
      let array = edges
      let arrayDay = array.filter(
        item =>
          item.node.acfJedilnik.jedilnik.dan === day &&
          item.node.acfJedilnik.jedilnik.cenaDnevniJedilnik
      )

      let piceArray = array.filter(
        item =>
          item.node.acfJedilnik.jedilnik.imeJedi === "Margerita" ||
          item.node.acfJedilnik.jedilnik.imeJedi === "Romana" ||
          item.node.acfJedilnik.jedilnik.imeJedi === "Klasika"
      )

      let kosilaArray = array.filter(
        item =>
          item.node.acfJedilnik.jedilnik.dan === "Nedelja" ||
          item.node.acfJedilnik.jedilnik.dan === "Sobota"
      )

      let kosilaArrayFiltered = kosilaArray.filter(item => {
        return (
          item.node.acfJedilnik.jedilnik.imeJedi !== "Margerita" &&
          item.node.acfJedilnik.jedilnik.imeJedi !== "Romana" &&
          item.node.acfJedilnik.jedilnik.imeJedi !== "Klasika"
        )
      })
      let joinedArray = [...piceArray, ...arrayDay]

      if (day != "Sobota" && day != "Nedelja") {
        var half_length = Math.ceil(joinedArray.length / 2)
        var leftSide = joinedArray.slice(0, half_length)
        var rightSide = joinedArray.slice(half_length, joinedArray.length)

        setDnevni(leftSide)
        setDesno(rightSide)
        setLoaded(true)
      } else {
        var half_length = Math.ceil(kosilaArrayFiltered.length / 2)
        var leftSide = kosilaArrayFiltered.slice(0, half_length)
        var rightSide = kosilaArrayFiltered.slice(
          half_length,
          kosilaArrayFiltered.length
        )

        setDnevni(leftSide)
        setDesno(rightSide)
      }
    }
  }, [day])

  console.log("dnevni", dnevni)

  console.log("day", day)
  return (
    <section className="menus menu section-padding pb-120">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 mb-40 text-center">
            {day === "Sobota" || day === "Nedelja" ? (
              <h6 className="sub-title">Vikend kosila</h6>
            ) : (
              <h6 className="sub-title">{day}</h6>
            )}
            {day === "Sobota" || day === "Nedelja" ? (
              <h4 className="title">Kosila</h4>
            ) : (
              <h4 className="title">Dnevne malice</h4>
            )}
          </div>
        </div>

        <div className="menus-content">
          <div className="cont active">
            <div className="row">
              <div className="col-md-5">
                {dnevni ? (
                  dnevni.map((item, i) => (
                    <DnevniItem item={item} key={"menu-item" + i} />
                  ))
                ) : (
                  <p>Nalagam</p>
                )}
              </div>
              <div className="col-md-5 offset-md-2">
                {dnevni ? (
                  dnevniDesno.map((item, i) => (
                    <DnevniItem item={item} key={"menu-item" + i} />
                  ))
                ) : (
                  <p>Nalagam</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Dnevni
