import React, { Fragment } from "react"

const DailyItem = ({ item }) => {
  let chosenItem

  if (item.node) {
    chosenItem = (
      <Fragment>
        <h5>
          {item.node.acfJedilnik.jedilnik.imeJedi}
          <span className="price">
            {item.node.acfJedilnik.jedilnik.cenaDnevniJedilnik === null
              ? item.node.acfJedilnik.jedilnik.cena
              : item.node.acfJedilnik.jedilnik.cenaDnevniJedilnik}{" "}
            eur
          </span>
        </h5>
        <p className={
          item.node.acfJedilnik.jedilnik.opisJedi
            ? ""
            : "empty-description"
        }>
          {item.node.acfJedilnik.jedilnik.opisJedi} &nbsp;
        </p>
      </Fragment>
    )
  } else {
    return <p>Nalagam</p>
  }
  return (
    <div>
      <div className="menu-info">{chosenItem}</div>
    </div>
  )
}

export default DailyItem
