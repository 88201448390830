import React, { useState } from "react"
import Slider from "react-slick"
import {graphql, useStaticQuery} from 'gatsby'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Izjave = () => {
  const data = useStaticQuery(graphql`
   query {
      HWGraphQL {
        page(id: "cG9zdDo4MQ==") {
          acfIzjave {
            prvaIzjava {
              ime
              izjava
              lokacija
            }
            tretjaIzjava {
              ime
              izjava
              lokacija
            }
            drugaIzjava {
              ime
              izjava
              lokacija
            }
          }
        }
      }
    }
  `)


  const {HWGraphQL: {page: { acfIzjave}}} = data;
  let izjave = acfIzjave;

  const [settings, setSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 0,
    useCSS: true,
    useTransform: true,
    autoplaySpeed: 5000,
    autoplay: true,
    pauseOnHover: false,
    lazyLoad: true,
    dotsClass: "slider-dots slider-dots--light",
  })
  return (
    <section className="picerija-slider picerija-slider--testimonials" style={{ backgroundImage: 'url(/images/testimonials-bg-image.jpg)' }}>
      <Slider
        style={{
          overflowX: "hidden",
        }}
        {...settings}
      >
        <div className="picerija-slider__item">
          <div className="picerija-slider__item-wrapper">
            <h6>{izjave.prvaIzjava.ime}</h6>
            <span style={{ color: "#aa1d21", marginBottom: "1rem" }}>
              {izjave.prvaIzjava.lokacija}
            </span>
            <p>{izjave.prvaIzjava.izjava}</p>
          </div>
        </div>
        <div className="picerija-slider__item">
          <div className="picerija-slider__item-wrapper">
            <h6>{izjave.drugaIzjava.ime}</h6>
            <span style={{ color: "#aa1d21", marginBottom: "1rem" }}>
              {izjave.drugaIzjava.lokacija}
            </span>
            <p>{izjave.drugaIzjava.izjava}</p>
          </div>
        </div>
        <div className="picerija-slider__item">
          <div className="picerija-slider__item-wrapper">
            <h6>{izjave.tretjaIzjava.ime}</h6>
            <span style={{ color: "#aa1d21", marginBottom: "1rem" }}>
              {izjave.tretjaIzjava.lokacija}
            </span>
            <p>{izjave.tretjaIzjava.izjava}</p>
          </div>
        </div>
      </Slider>
    </section>
  )
}

export default Izjave
