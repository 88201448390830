import React, { Fragment, useEffect } from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import { withPrefix } from "gatsby-link"
import SEO from "../components/seo"
import Dnevni from "../components/Dnevni"
import Banner from "../components/Banner"
import SideContent from "../components/SideContent"
import Izjave from "../components/Izjave"
import Contact from "../components/Contact"
import TakeAway from "../components/Takeaway"

const IndexPage = ({ location }) => {
const [day, setDay] = React.useState()

useEffect(() => {
  const dnevi = ['Nedelja', 'Ponedeljek', 'Torek', 'Sreda', 'Četrtek', 'Petek', 'Sobota']
  let d = new Date();
  let dan = dnevi[d.getDay()]

  setDay(dan)

  }, [])

  const data = useStaticQuery(graphql`
    query {
      HWGraphQL {
        infoPage: page(id: "cG9zdDozMA==") {
          acfMeta {
            informacije {
              delovnicasdnevi
              delovnicasura
              telefonska
              naslov
            }
            takeaway {
              malinaslov
              veliknaslov
            }
          }
        }
      }
    }
  `)
  const {
    HWGraphQL: {
      infoPage: {
        acfMeta: { informacije, takeaway },
      },
    },
  } = data
  return (
    <Layout>
      <SEO title="Domov" />
      <Banner />
      
       <Dnevni day={day}/>

      {/* <section className="menus menu section-padding pb-120">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 mb-40 text-center">
              <h4 className="title">Obvestilo</h4>
            </div>
          </div>

          <div className="menus-content">
            <div className="cont active">
              <p className="text-center">
                Cenjene stranke obveščamo, da bo zaradi spleta okoliščin picerija Jaka <strong>od 17.12. do vključno 27.12.</strong> zaprta.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <Izjave />
      <Contact informacije={informacije} />
      <TakeAway informacije={informacije} takeaway={takeaway} />
    </Layout>
  )
}
export default IndexPage
