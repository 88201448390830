import React from "react"

const TakeAway = ({ takeaway, informacije }) => {

  const { malinaslov, veliknaslov } = takeaway
  const {delovnicasdnevi, delovnicasura, telefonska} = informacije
  
  return (
    <div className="takeaway-banner section-padding text-center"
      style={{ backgroundImage: 'url(/images/reservations-bg-image.jpg)' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h6 className="sub-title">{malinaslov}</h6>
            <h4>{veliknaslov}</h4>
            <p dangerouslySetInnerHTML={{
              __html: informacije.delovnicasdnevi
              }}>
            </p>
            <a href="tel:{telefonska.split('<br />')[0]}" className="butn butn-bg">
              <span>
                <i className="ti-phone"></i> {telefonska.split('<br />')[0]}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TakeAway
